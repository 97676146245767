@import "../../node_modules/bootstrap/scss/bootstrap-reboot";

$side: 2rem;
$extra-light: 200;
$light: 300;
$semi-bold: 600;
$blue: #2b9dbb;
$blue--dark: darken($blue, 7%);
$blue--darker: darken($blue, 15%);
$dark-blue: #313948;

a {
  color: $blue;

  &:hover {
    color: $dark-blue;
  }
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

:focus {
  outline-color: $blue;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: $light;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  align-items: stretch;
}

$menu-width: 20rem;
html.disable-scroll {
  &,
  & body {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
  }

  & body {
    height: 100%;
  }
}

.site {
  flex: 1;
  overflow-x: hidden;
  position: relative;

  &.is-open {
    overflow: hidden;
  }
}

.site__page {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform ease 0.5s;

  @include media-breakpoint-down(md) {
    position: relative;

    .site.is-open & {
      transform: translateX(-$menu-width);
      overflow: hidden;

      &::after {
        visibility: visible;
        opacity: 1;
      }
    }

    &::after {
      content: '';
      display: block;
      transition: opacity ease .5s;
      background: rgba(0,0,0,.5);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.site__menu {
  width: $menu-width;
  position: fixed;;
  top: 0;
  right: 0;
  bottom: 0;
  transition: transform ease 0.5s;
  transform: translateX($menu-width);
  background: darken($dark-blue, 5%);
  box-shadow: inset 100px 0px 100px -100px rgba(0,0,0,0.5);

  @include media-breakpoint-down(md) {
    .site.is-open & {
      transform: translateX(0);
    }
  }
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  align-items: flex-start;
}

.mobile-menu__link {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: normal;
  color: white;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &.is-active {
    border-bottom-color: rgba(255, 255, 255, .5);
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    border-bottom-color: white;
  }
}

.header {
  background-color: #313948;
  background: linear-gradient(-25deg, #49556d, #313948);
  color: white;
  padding: 0 $side;
  position: relative;
}

.header__top {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.header__logo {
  height: 2.5rem;
}

.header__burger {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.header__nav {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    color: white;
  }
}

.header__burger__icon {
  fill: white;
}

.nav__link {
  color: white;
  border-bottom: 2px solid transparent;
  font-weight: normal;
  font-size: 1rem;
  text-transform: uppercase;

  & + & {
    margin-left: 1rem;

    @include media-breakpoint-up(lg) {
      margin-left: 2rem;
    }
  }

  &.is-active {
    border-bottom-color: rgba(255, 255, 255, .5);
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    border-bottom-color: white;
  }
}

.hero {
  overflow: hidden;
  padding-bottom: 4rem;
}

.hero__title {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: $extra-light;
  margin: 6rem 0 0 0;
}

.hero__subtitle {
  font-size: 1rem;
  font-weight: normal;
  margin: 1.5rem 0 0 0;
}

.hero__actions {
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btn {
  margin-top: 1rem;
  border: 1px solid white;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0 1.5rem;
  display: flex;
  width: auto;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  line-height: 1;
  transition: background ease .2s;

  &:hover,
  &:focus {
    background: rgba(255,255,255,.1);
    color: white;
    text-decoration: none;
  }

  &:active {
    background: rgba(255,255,255,.2);
  }
}

.btn--primary {
  background: $blue;
  border: none;

  &:hover,
  &:focus {
    background: $blue--dark;
    color: white;
    text-decoration: none;
  }

  &:active {
    background: $blue--darker;
  }
}

.services {
  padding-top: 2rem;

  @include media-breakpoint-up(md) {
    display: flex;
    margin-left: -$side;
    margin-right: -$side;
  }
}

.service {
  padding: 2.5rem $side 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.service__icon {
  height: 4.5rem;
  width: 4.5rem;
  color: #b1b4b9;
  fill: currentColor;
}

.service__title {
  margin: 0;
  padding-top: 1rem;
  font-weight: $semi-bold;
  font-size: 1rem;
  text-transform: uppercase;
}

.service__text {
  padding-top: .5rem;
  margin: 0;
  text-align: center;
  max-width: 22rem;
}

.about {
  background: $blue;
  color: white;
  padding: 0 $side;
}

.about__container {
  display: flex;
  flex-direction: column;
}

.about__title {
  margin: 4rem 0 0 0;
  font-weight: $extra-light;
  font-size: 2rem;
  line-height: 2.5rem;
}

.about__text {
  padding-top: 2rem;
  margin: 0;
  font-weight: normal;
  max-width: 45rem;
}

.about__more {
  display: block;
  margin: 2.5rem auto 4rem;
  align-self: center;
  color: white;
  text-decoration: underline;
  font-weight: normal;

  &:hover,
  &:focus {
    color: $dark-blue;
  }

  &:focus {
    outline-color: $dark-blue;
  }
}

.showcase {
  padding: 3rem $side 0;
}

.showcase__title {
  margin: 0 0 3rem 0;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: $extra-light;
}

.showcase__items {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$side/2;
    margin-right: -$side/2;
    margin-bottom: $side;
  }
}

.showcase__item {
  display: block;
  position: relative;
  margin-bottom: 2.5rem;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding-left: $side/2;
    padding-right: $side/2;
    margin-bottom: $side;
  }
}

.showcase__item__title {
  position: absolute;
  left: 2rem;
  right: 2rem;
  top: 1rem;
  text-align: center;
  font-weight: normal;
  font-size: 1.25rem;
  color: #313948;
  margin: 0;

  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}

.showcase__item__title--light {
  color: white;
}

.showcase__item__bg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer {
  flex: 1;
  flex-shrink: 0;
  background: $dark-blue;
  color: white;
  padding: 2.5rem $side;
  font-weight: normal;
}

.footer__container {
  @include media-breakpoint-up(md) {
    display: flex;
    margin-left: -$side;
    margin-right: -$side;
  }
}

.footer__address {
  line-height: 1.5rem;

  @include media-breakpoint-up(md) {
    display: block;
    width: 50%;
    padding-left: $side;
    padding-right: $side;
  };
}

.footer__sitemap {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;

  @include media-breakpoint-up(md) {
    display: block;
    width: 50%;
    margin-top: 0;
    padding-left: $side;
    padding-right: $side;
  };
}

.footer__sitemap__item {
  margin: 0;
}

.footer__sitemap__item__link {
  color: white;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: white;
  }
}

.bodycopy {
  margin: 2rem 0;
  padding: 0 2rem;

  h1, h2, h3, h4, h5, h6, p {
    max-width: 40rem;
  }
}

.Inputfield {
  display: flex;
}

.InputfieldHeader {
  font-weight: normal;
  width: 300px;
}

.InputfieldContent {
  display: flex;
  flex-direction: row-reverse;;
}
